import React from "react"
import { Box, DataTable, Heading, Paragraph, ResponsiveContext } from "grommet"
import { StatusGood } from "grommet-icons"

import { themeExtension } from "../../styles/theme"

const HeaderItem = ({ title, color, last = false, fontColor = "brand" }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={size === "small" && last ? { right: "large" } : {}}
          align="center"
        >
          <Box
            background={color}
            pad="xsmall"
            width={size === "small" ? "45px" : "xsmall"}
            round="small"
            margin="xsmall"
          >
            <Paragraph size="small" margin="none" color={fontColor}>
              {size === "small" ? title.small : title.big}
            </Paragraph>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const CheckItem = ({ item = "", plan, last = false }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={
            size === "small" && last
              ? { vertical: "small", right: "large" }
              : { vertical: "small" }
          }
          align="center"
        >
          {item && item[plan] ? (
            <StatusGood color="brand" />
          ) : (
            <StatusGood color="light-5" />
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const columns = [
  {
    property: "feature",
    header: (
      <Box pad={{ left: "large", vertical: "xxsmall", right: "none" }}>
        <Heading level={3} color="dark-1">
          Features
        </Heading>
      </Box>
    ),
    primary: true,
    render: item => {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Box pad={{ left: "large", vertical: "xsmall", right: "none" }}>
              <Paragraph
                size={size === "small" ? "small" : "medium"}
                color="dark-3"
                style={
                  size === "small"
                    ? { fontWeight: "400", maxWidth: "100%" }
                    : { fontWeight: "200", maxWidth: "100%" }
                }
              >
                {item.feature}
              </Paragraph>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      )
    },
  },
  {
    align: "center",
    property: "feature_starter",
    header: (
      <HeaderItem
        title={{ big: "Basic", small: "Basic" }}
        color="accent-1"
        fontColor="light-2"
      />
    ),
    render: item => <CheckItem item={item} plan="feature_starter" />,
  },
  {
    align: "center",
    property: "feature_professional",
    header: (
      <HeaderItem
        title={{ big: "Professional", small: "Pro" }}
        color="accent-2"
      />
    ),
    render: item => <CheckItem item={item} plan="feature_professional" />,
  },
  {
    align: "center",
    property: "feature_business",
    header: (
      <HeaderItem
        last
        title={{ big: "Business", small: "Biz" }}
        color="accent-3"
      />
    ),
    render: item => <CheckItem item={item} plan="feature_business" last />,
  },
]

const Table = ({ data }) => {
  return (
    <DataTable
      columns={columns}
      border={{ color: "transparent" }}
      data={data}
      pad="none"
      background={{
        header: "#F1F4F8",
        body: ["transparent", themeExtension.colors.backgrounds.light],
      }}
      style={{ width: "100%" }}
    />
  )
}

export default Table
